var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"box-card",attrs:{"shadow":"never"}},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('el-row',{staticClass:"set-flex",attrs:{"gutter":30}},[_c('el-col',{staticClass:"set-flex",attrs:{"span":24,"md":7}},[_c('div',{staticClass:"group-title"},[_c('div',{staticClass:"logo-in-card"},[_c('img',{attrs:{"src":require("../assets/image/icon/info.svg")}})]),_c('div',{staticClass:"name-title"},[_vm._v("ชื่อโครงการนวัตกรรม")])])]),_c('el-col',{attrs:{"span":24,"md":17}},[_c('p',{staticClass:"name-inno"},[_vm._v(_vm._s(_vm.dataInnovation.projectName))])])],1)],1),_c('el-table',{staticClass:"custom-table-innovation",staticStyle:{"width":"100%"},attrs:{"data":_vm.dataInnovation.innovationList}},[_c('el-table-column',{attrs:{"width":_vm.widthMax < 767.98
          ? '220'
          : _vm.widthMax < 1199
          ? '220'
          : _vm.widthMax > 1200
          ? ''
          : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.name))]}}])},[_vm._v(" > "),_c('template',{slot:"header"},[_c('div',{staticClass:"group-title"},[_c('div',{staticClass:"logo-in-card"},[_c('img',{attrs:{"src":require("../assets/image/icon/quality.svg")}})]),_c('div',{staticClass:"name-title"},[_vm._v("รายการนวัตกรรม")])])])],2),_c('el-table-column',{attrs:{"width":_vm.widthMax < 767.98
          ? '220'
          : _vm.widthMax < 1199
          ? '220'
          : _vm.widthMax > 1200
          ? ''
          : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 1)?_c('span',[_vm._v("Diagnostics and Laboratory technology")]):_vm._e(),(scope.row.type == 2)?_c('span',[_vm._v("Medical Devices")]):_vm._e(),(scope.row.type == 3)?_c('span',[_vm._v("Pharmaceutical and therapeutic")]):_vm._e(),(scope.row.type == 4)?_c('span',[_vm._v("Immunization, vaccine and biologicals")]):_vm._e(),(scope.row.type == 5)?_c('span',[_vm._v("eHealth, mHealth, Telehealth, Medical software and Imaging")]):_vm._e(),(scope.row.type == 6)?_c('span',[_vm._v("อื่นๆ")]):_vm._e()]}}])},[_vm._v("> "),_c('template',{slot:"header"},[_c('div',{staticClass:"group-title"},[_c('div',{staticClass:"logo-in-card"},[_c('img',{attrs:{"src":require("../assets/image/icon/quality.svg")}})]),_c('div',{staticClass:"name-title"},[_vm._v("ประเภทนวัตกรรม")])])])],2),_c('el-table-column',{attrs:{"width":_vm.widthMax < 767.98
          ? '220'
          : _vm.widthMax < 1199
          ? '220'
          : _vm.widthMax > 1200
          ? ''
          : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.level == 1)?_c('span',[_vm._v("องค์ความรู้และงานวิจัยพื้นฐาน")]):_vm._e(),(scope.row.level == 2)?_c('span',[_vm._v("ต้นแบบห้องปฏิบัติการ")]):_vm._e(),(scope.row.level == 3)?_c('span',[_vm._v("ต้นแบบภาคสนาม")]):_vm._e(),(scope.row.level == 4)?_c('span',[_vm._v("พร้อมออกสู่ตลาด")]):_vm._e()]}}])},[_vm._v("> "),_c('template',{slot:"header"},[_c('div',{staticClass:"group-title"},[_c('div',{staticClass:"logo-in-card"},[_c('img',{attrs:{"src":require("../assets/image/icon/quality.svg")}})]),_c('div',{staticClass:"name-title"},[_vm._v(" ระดับความพร้อมของเทคโนโลยีสู่อุตสาหกรรม (TRL) ")])])])],2),_c('el-table-column',{attrs:{"width":_vm.widthMax < 767.98
          ? '220'
          : _vm.widthMax < 1199
          ? '220'
          : _vm.widthMax > 1200
          ? ''
          : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.sourceFund))]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"group-title"},[_c('div',{staticClass:"logo-in-card"},[_c('img',{attrs:{"src":require("../assets/image/icon/handshake.svg")}})]),_c('div',{staticClass:"name-title"},[_vm._v("ความร่วมมือ / แหล่งเงินทุนสนับสนุน")])])])],2),_c('el-table-column',{attrs:{"width":_vm.widthMax < 767.98
          ? '120'
          : _vm.widthMax < 1199
          ? '120'
          : _vm.widthMax > 1200
          ? '120'
          : ''},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"list-innovation"},[_c('span',{staticClass:"read-another"},[(scope.row.attach.file != null)?_c('a',{attrs:{"target":"_blank","href":`${_vm.callImage}service/${_vm.dataInnovation.serviceId}/${scope.row.attach.file}`}},[_vm._v("อ่านเพิ่มเติม")]):_c('a',{staticStyle:{"cursor":"no-drop"}},[_vm._v("อ่านเพิ่มเติม")])])])]}}])},[_c('template',{slot:"header"})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }