<template>
  <el-card class="box-card" shadow="never">
    <div slot="header" class="clearfix">
      <el-row :gutter="30" class="set-flex">
        <el-col :span="24" :md="7" class="set-flex">
          <div class="group-title">
            <div class="logo-in-card">
              <img src="../assets/image/icon/info.svg" />
            </div>
            <div class="name-title">ชื่อโครงการนวัตกรรม</div>
          </div>
        </el-col>
        <el-col :span="24" :md="17">
          <p class="name-inno">{{ dataInnovation.projectName }}</p>
        </el-col>
      </el-row>
    </div>
    <!-- <el-row :gutter="30" class="set-flex box-list">
      <el-col :span="24" :md="7" class="set-flex">
        <div class="group-title">
          <div class="logo-in-card">
            <img src="../assets/image/icon/quality.svg" />
          </div>
          <div class="name-title">รายการนวัตกรรม</div>
        </div>
      </el-col>
    </el-row> -->
    <!-- <div v-for="">

    </div> -->
    <el-table
      :data="dataInnovation.innovationList"
      style="width: 100%"
      class="custom-table-innovation"
    >
      <el-table-column
        :width="
          widthMax < 767.98
            ? '220'
            : widthMax < 1199
            ? '220'
            : widthMax > 1200
            ? ''
            : ''
        "
      >
        >
        <template slot="header">
          <div class="group-title">
            <div class="logo-in-card">
              <img src="../assets/image/icon/quality.svg" />
            </div>
            <div class="name-title">รายการนวัตกรรม</div>
          </div>
        </template>
        <template slot-scope="scope"> {{ scope.row.name }}</template>
      </el-table-column>
      <el-table-column
        :width="
          widthMax < 767.98
            ? '220'
            : widthMax < 1199
            ? '220'
            : widthMax > 1200
            ? ''
            : ''
        "
        >>
        <template slot="header">
          <div class="group-title">
            <div class="logo-in-card">
              <img src="../assets/image/icon/quality.svg" />
            </div>
            <div class="name-title">ประเภทนวัตกรรม</div>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1"
            >Diagnostics and Laboratory technology</span
          >
          <span v-if="scope.row.type == 2">Medical Devices</span>
          <span v-if="scope.row.type == 3">Pharmaceutical and therapeutic</span>
          <span v-if="scope.row.type == 4"
            >Immunization, vaccine and biologicals</span
          >
          <span v-if="scope.row.type == 5"
            >eHealth, mHealth, Telehealth, Medical software and Imaging</span
          >
          <span v-if="scope.row.type == 6">อื่นๆ</span>
        </template>
      </el-table-column>
      <el-table-column
        :width="
          widthMax < 767.98
            ? '220'
            : widthMax < 1199
            ? '220'
            : widthMax > 1200
            ? ''
            : ''
        "
        >>
        <template slot="header">
          <div class="group-title">
            <div class="logo-in-card">
              <img src="../assets/image/icon/quality.svg" />
            </div>
            <div class="name-title">
              ระดับความพร้อมของเทคโนโลยีสู่อุตสาหกรรม (TRL)
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.level == 1">องค์ความรู้และงานวิจัยพื้นฐาน</span>
          <span v-if="scope.row.level == 2">ต้นแบบห้องปฏิบัติการ</span>
          <span v-if="scope.row.level == 3">ต้นแบบภาคสนาม</span>
          <span v-if="scope.row.level == 4">พร้อมออกสู่ตลาด</span>
        </template>
      </el-table-column>
      <el-table-column
        :width="
          widthMax < 767.98
            ? '220'
            : widthMax < 1199
            ? '220'
            : widthMax > 1200
            ? ''
            : ''
        "
      >
        <template slot="header">
          <div class="group-title">
            <div class="logo-in-card">
              <img src="../assets/image/icon/handshake.svg" />
            </div>
            <div class="name-title">ความร่วมมือ / แหล่งเงินทุนสนับสนุน</div>
          </div>
        </template>
        <template slot-scope="scope"> {{ scope.row.sourceFund }}</template>
      </el-table-column>
      <el-table-column
        :width="
          widthMax < 767.98
            ? '120'
            : widthMax < 1199
            ? '120'
            : widthMax > 1200
            ? '120'
            : ''
        "
      >
        <template slot="header"> </template>
        <template slot-scope="scope">
          <p class="list-innovation">
            <span class="read-another">
              <a
                target="_blank"
                v-if="scope.row.attach.file != null"
                :href="`${callImage}service/${dataInnovation.serviceId}/${scope.row.attach.file}`"
                >อ่านเพิ่มเติม</a
              >
              <a v-else style="cursor: no-drop">อ่านเพิ่มเติม</a>
            </span>
          </p>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="level"> </el-table-column> -->
    </el-table>
    <!-- <ul class="list-innovation">
      <li
        v-for="(list_innovation, index) in dataInnovation.innovationList"
        :key="index"
      >
        <p>
          <a href>{{ list_innovation.name }}</a>
          <span class="read-another" >
            <a target="_blank"  v-if="list_innovation.attach.file != null" :href="`${callImage}service/${dataInnovation.serviceId}/${list_innovation.attach.file}`"
              >อ่านเพิ่มเติม</a
            >
            <a v-else style="cursor:no-drop">อ่านเพิ่มเติม</a>
          </span>
        </p>
      </li>
    </ul> -->
  </el-card>
</template>
<style lang="scss" scope>
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #ffffff;
}
.el-table__body-wrapper {
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #9e76b4;
  }
}
.card-innovation {
  position: relative;
  &::after{
    
  @media (max-width:991.98px) {
    content: 'Slide >>';
    position: absolute;
    top: 50%;
    right: 15px;
    font-family: "Kanit", sans-serif;
    color: #502ABE;
    font-size: 14px;
    text-shadow: 0 0 3px #FFf;
    font-weight: 500;
-webkit-animation:linear infinite alternate;
-webkit-animation-name: run;
-webkit-animation-duration: 4s;
  }
  @-webkit-keyframes run {
    0% { right: 0;}
    50%{ right : 25px;}
    100%{ right: 0;}
}
  }
}
.cell {
  word-break: break-word !important;
}
</style>
<script>
export default {
  props: ["dataInnovation"],

  data() {
    return {
      widthMax: window.innerWidth,
      callImage: process.env.VUE_APP_BASE_URL_GET,
    };
  },
};
</script>