<template>
  <div id="service" class="content">
    <div class="search-service">
      <div class="container service-of">
        <span class="befor-title" v-if="search == undefined">นวัตกรรมของ</span>
        <span class="title-inside" v-if="search == undefined">{{
          serviceName
        }}</span>
        <span class="title-inside" v-if="search != undefined"
          >นวัตกรรมจากย่านการแพทย์สวนดอก</span
        >
      </div>
    </div>
    <div v-loading="loading" class="inside-page">
      <div class="container" v-if="innovation.length != 0">
        <p
          class="count-search"
          v-if="search != undefined"
          :style="
            tags != undefined
              ? 'display:none'
              : search == 'all'
              ? 'display:none'
              : ''
          "
        >
          <span style="padding-right: 10px">คำที่คุณค้นหา</span>
          <span class="active">{{ search }}</span>
        </p>
        <p class="count-search">
          นวัตกรรมทั้งหมด
          <span class="active">{{ allCount }}</span> รายการ
        </p>

        <div
          class="card-innovation"
          v-for="(dataInnovations, index) in innovation"
          :key="index"
        >
          <cardInnovation :dataInnovation="dataInnovations" />
        </div>
      </div>
      <div class="container text-center" v-else>
        <p class="count-search">ไม่พบข้อมูลที่คุณค้นหา</p>
      </div>
      <!-- <div class="pd-y-3 text-center">
          <el-pagination
            @current-change="changePage"
            background
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            :current-page="page"
          ></el-pagination>
        </div> -->
    </div>
  </div>
</template>
<script>
import cardInnovation from "@/components/cardInnovation";
import { HTTP } from "@/service/axios.js";

export default {
  components: {
    cardInnovation,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      serviceId: "",
      serviceName: "",
      loading: true,
      page: 1,
      total: 0,
      pageSize: 10,
      allCount: 0,
      search:'',
      innovation:[]
    };
  },
  mounted() {
    this.setParams();
  },
  methods: {
    setParams() {
      if (this.routeParams != null) {
        this.serviceId = this.routeParams.serviceId;
        this.search = this.routeParams.search;
        this.tags = this.routeParams.tags;
      }
      if (this.search == undefined) {
        this.fetchServiceById();
        this.fetchInnovation();
      } else {
        this.fetchInnovationSearch();
      }
      // console.log("serviceId", this.serviceId);
      // console.log("search", this.search);
    },
    changePage(page) {
      this.page = page;
      this.fetchInnovationSearch();
    },
    fetchInnovationSearch() {
      var getTags = "";
      if (this.tags == undefined) {
        getTags = "all";
      } else {
        getTags = this.tags;
      }
      HTTP.get(
        `innovations/${this.search}/${this.pageSize}/${
          (this.page - 1) * this.pageSize
        }/${getTags}`
      )
        .then((res) => {
          if (res.data.success) {
            this.innovation = res.data.obj;
             for (var a = 0; a < this.innovation.length; a++) {
              var sum = this.innovation[a].innovationList.length;
              this.allCount += sum;
            }
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchInnovation error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    fetchInnovation() {
      HTTP.get(`innovation/${this.serviceId}`)
        .then((res) => {
          if (res.data.success) {
            this.innovation = res.data.obj;
            for (var a = 0; a < this.innovation.length; a++) {
              var sum = this.innovation[a].innovationList.length;
              this.allCount += sum;
            }
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("fetchInnovation error", e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 500);
        });
    },
    fetchServiceById() {
      HTTP.get(`service/${this.serviceId}`).then((res) => {
        if (res.data.success) {
          this.serviceName = res.data.obj.nameTH;
        }
      });
    },
  },
};
</script>
